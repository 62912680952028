import request from '../request'

//车辆大小类型列表
export const car_size_type_index_request = p => {
    return request({
        method:'GET',
        url:'car_size_type/index',
        params: p
    })
}

//添加车辆大小类型
export const car_size_type_add_request = d => {
    return request({
        method:'POST',
        url:'car_size_type/add',
        data: d
    })
}

//删除车辆大小类型
export const car_size_type_del_request = id => {
    return request({
        method:'DELETE',
        url:'car_size_type/del',
        data: {
            id: id
        }
    })
}

//修改车辆大小类型
export const car_size_type_edit_request = d => {
    return request({
        method:'PUT',
        url:'car_size_type/edit',
        data: d
    })
}

//获取车辆大小类型详情
export const car_size_type_details_request = id => {
    return request({
        method:'GET',
        url:'car_size_type/details',
        params: {
            id
        }
    })
}